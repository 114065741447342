@import '../helpers/variables';
@import '../mixins';

.dnav {
  display: flex;
  flex-wrap: wrap;


  &__image {
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-basis: 25%;
    flex-grow: 1;
    position: relative;

    &--zoom {
      background-position: 50% 50%;
      background-size: 125% auto;
    }

    &::before,
    &::after {
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    &::before {
      background-image: url('../img/news/inner-arrow.png');
      background-position: left 0;
    }

    &::after {
      background-image: url('../img/car-history-check/overlay--f3.png');
      background-position: right 0;
    }

    &--to-white::after {
      background-image: url('../img/news/overlay.png');
    }

    &--noimage {
      align-items: center;
      background-color: #fff;
      justify-content: center;

      &::before {
        display: none;
      }

      @include max-mobile {
        background-color: #f3f3f3;
        flex-basis: 100%;

        &::after {
          display: none;
        }
      }
    }
  }

  &__main {
    align-items: center;
    display: flex;
    flex-basis: 45%;
    flex-grow: 1;
    justify-content: space-evenly;

    @include max-tablet {
      flex-direction: column-reverse;
    }

    &--bg {
      background-color: #f3f3f3;
      position: relative;

      &::before {
        background-image: url('../img/news/overlay.png');
        background-position: right 0;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;

        @include max-desktop {
          display: none;
        }
      }

      .dnav__title {
        margin-top: 0;
      }

      .dnav__main--bg .dnav__main--content {
        align-items: center;
        display: flex;
        height: 100%;
      }

      .dnav__main--content {
        width: 100%;
        @include min-tablet {
          width: auto;
        }
      }
    }
  }

  &__action {
    align-items: center;
    display: flex;
    flex-basis: 30%;
    flex-grow: 1;
    justify-content: center;

    @include max-tablet {
      flex-basis: 100%;
    }
  }




  &__vrm {
    background: #fdcb2b;
    border: 1px solid #a5a5a5;
    border-radius: 5px;
    color: #141414;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-size: 19px;
    font-weight: 600;
    margin: 14px 0 12px 1px;
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
    width: 135px;
  }
}



