@import '../helpers/variables';
@import '../mixins';

.dark-theme {
  --title-color: #{$white};
  --caret-color: #{$orange};
}

.accordion {
  border-bottom: 1px solid $grey;

  &__title {
    background-color: transparent;
    border: 0;
    border-top: 1px solid $grey;
    color: var(--title--color);
    cursor: pointer;
    font-family: $font-family;
    font-weight: bold;
    outline: none;
    padding: 18px 36px 18px 18px;
    position: relative;
    text-align: left;
    transition: 0.4s;
    width: 100%;

    &::after {
      color: var(--caret-color, $grey-darkest);
      content: '\f107';
      font-family: 'fontello';
      font-size: 20px;
      font-weight: bold;
      margin-left: 5px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--active {
      + .accordion__panel {
        max-height: min-content;
      }

      &::after {
        content: '\f106';
      }
    }
  }

  &__panel {
    max-height: 0;
    overflow: hidden;
    padding: 0 18px;
    transition: max-height 0.2s ease-in-out;

  }

  li {
    margin-bottom: 10px;
  }
}
