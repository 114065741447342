// Imports
@import '../helpers/variables';
@import '../mixins';

.reviews-block {
  &__item:first-child {
    @include max-desktop {
      padding-top: 10px;
    }
  }

  &__item {
    padding-top: 20px;
    @include max-desktop {
      padding-top: 50px;
    }

    &__message {
      font-style: italic;
      font-weight: 300;
      min-height: 60px;
      padding: 10px 0 0;
      text-align: center;

      @include max-desktop {
        min-height: 0;
      }
    }

    &__name {
      padding: 10px 0 0;

      &--orange {
        color: $orange-darkest;
      }
    }

    &__place {
      padding: 10px 0 0;

      &--orange {
        color: $orange-darkest;
      }
    }
  }

  &__logo {
    img {
      width: 240px;
    }
  }
}
