@import '../helpers/variables';
@import '../mixins';
@import '../components/dnav';
@import '../components/flow_breadcrumbs';
@import '../components/ghostInfo';
@import '../components/accordion';
@import '../components/flow-progressbar';
@import '../components/flow-header';
@import '../components/wcards';
@import '../layout/navigation';
@import '../components/reviews-block';
@import '../components/timeline';
@import '../components/mot-history-card';
@import '../components/hero-v2';

.flow-hero {
  &--valuation {
    background: url('../img/flows/car_valuation.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 70%;

    @include max-tablet {
      background-position: center top;
      background-size: 75%;
      padding-top: 40%;
    }
  }

  &--van-valuation {
    background: url('../img/flows/van_valuation.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 30%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 50%;
      padding-top: 40%;
    }
  }

  &--mot-history {
    background: url('../img/flows/mot_history_check.webp'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 55%;

    @include max-tablet {
      background-position: center 30px;
      background-size: 75%;
      padding-top: 60%;
    }
  }

  &--rhomboidal {
    background-clip: padding-box;
    background-color: #FFF;
    background-image: image-set(
        url('../img/home/frog-hero.webp') 1x,
        url('../img/home/frog-hero.webp') 2x
    );
    background-position: 70%;
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 450px !important;

    &--home {
      background-image: image-set(
          url('../img/home/frog-hero.webp') 1x,
          url('../img/home/frog-hero.webp') 2x
      );

      &--mobile {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 200px;
      }
    }

    &--mot-history-check {
      background-image: image-set(
          url('../img/flows/mot_ipad_hero@1x.webp') 1x,
          url('../img/flows/mot_ipad_hero@2x.webp') 2x
      );

      &--mobile {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 200px;
      }
    }


  }

  &--mot-check {
    background: url('../img/flows/mot_check.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 55%;

    @include max-tablet {
      background-position: center 30px;
      background-size: 75%;
      padding-top: 60%;
    }
  }

  &--maintain {
    background: url('../img/flows/tlc.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 50%;

    @include max-tablet {
      background-position: center 30px;
      background-size: 85%;
      padding-top: 60%;
    }
  }

  &--tax-check {
    background: url('../img/flows/tax_check.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 30%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 40%;
      padding-top: 60%;
    }
  }

  &--settlement {
    background: url('../img/flows/settlement.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 30%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 40%;
      padding-top: 60%;
    }
  }

  &--warranty {
    background: url('../img/flows/warranty.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 40%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 85%;
      padding-top: 55%;
    }
  }

  &--credit-check {
    background: url('../img/flows/credit_rating.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 32%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 65%;
      padding-top: 55%;
    }
  }

  &--recalls {
    background: url('../img/svg/recall_badge_red.svg'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 50%;
    background-repeat: no-repeat;
    background-size: 40%;

    @include  max-tablet {
      background: url('../img/svg/recall_badge_red.svg'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
      background-position: center 20px;
      background-repeat: no-repeat;
      background-size: 50%;
      padding-top: 37.5%;
    }

    @include min-desktop {
      background: url('../img/svg/recalls-hero-full.svg'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
      background-position: right 50%;
      background-repeat: no-repeat;
      background-size: 60%;
    }
  }

  &--car-check {
    background: url('../img/flows/car-check.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 22%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 40%;
      padding-top: 55%;
    }
  }

  &--sell-your-car {
    background: url('../img/flows/sell-your-car.png'), radial-gradient(circle, $navy 0%, $navy-dark 35%);
    background-position: right 10%;
    background-repeat: no-repeat;
    background-size: 25%;

    @include max-tablet {
      background-position: center 20px;
      background-size: 57%;
      padding-top: 55%;
    }
  }
}

.title--flow {
  color: $white;
  margin: 0.5em 0;
}

.flow .button--cta {
  min-width: 175px;
}

// Optimizations for UX
.partners-list {
  height: 400px;
  list-style: disc;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px 0 20px 20px;

  li {
    font-family: $font-family-copy;
    font-size: 13px;
  }
}

.valuation-copy__icon {
  background-image: url('../img/landing/valuation/boxes-bg.png');
  display: inline-block;
  height: 110px;
  width: 110px;

  &--2 {
    background-position: top right;
  }

  &--3 {
    background-position: bottom left;
  }

  &--4 {
    background-position: bottom right;
  }
}

.inputLabel {
  color: $white;
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  text-indent: 1rem;

  @include min-tablet {
    font-size: 1.4rem;
  }
}

.vehicle-box {
  background-color: #1e2331;
  border-radius: 1rem;
  padding: 1rem;

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-around;

    @include max-desktop {
      flex-direction: column-reverse;
    }
  }

  &__text {
    margin-top: 1rem;
    @include max-desktop {
      text-align: center;
    }
  }

  &__vrm {
    @include max-desktop {
      margin-bottom: 2rem;
    }
  }
}

.aside-content {
  margin-left: 3rem;
  @include max-desktop {
    margin-left: 0;
    margin-top: 3rem;
  }
}

.aside-content-container {
  @include max-tablet {
    display: none;
  }
}

.row-reverse {
  flex-direction: row-reverse;
}

.what-you-get-blade {
  overflow: hidden;

  @include min-mobile {
    height: 1070px;
  }

  @include min-tablet {
    height: 920px;
  }

  @include min-desktop {
    height: 765px;
  }
}

.more-information {
  &__columns {
    columns: 1;

    @include min-tablet {
      column-gap: 15%;
      columns: 2;
    }
  }

  &__item {
    break-inside: avoid;
    margin-bottom: 3rem;
  }
}

.border-bottom {
  border-bottom: 1px solid $grey;
}

.reasons-list {
  columns: 1;
  list-style-position: outside;

  @include min-tablet {
    columns: 2;
  }

  li {
    margin-top: 1rem;
    padding-left: 7rem;
    page-break-inside: avoid;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      column-span: all;
    }

    &::before {
      color: #00cfe6;
      content: url('../../icons/mot_alert.svg');
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}

.hero-v2 {
  &-tax-check {
    &__content {
      padding: 40px;
    }

    &__container {
      grid-column-gap: 30px;
      min-height: 513px;
    }

    &__background::before {
      background-color: $grey-lightest;
      background-image: image-set(
          url('../img/flows/tax-check-hero.webp') 1x,
          url('../img/flows/tax-check-hero@2x.webp') 2x
      ), linear-gradient($grey-lightest, lighten($grey-lightest, 50%));
      @include min-desktop {
        background-position: 100% center;
      }
    }
  }

  &-mot-history-check {
    &__content {
      padding: 40px;
    }

    &__container {
      grid-column-gap: 30px;
      min-height: 576px;
    }

    &__background::before {
      background-image: image-set(
          url('../img/flows/mot_ipad_hero@1x.webp') 1x,
          url('../img/flows/mot_ipad_hero@2x.webp') 2x
      );
    }
  }

}
