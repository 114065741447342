@import '../helpers/variables';
@import '../mixins';

.fnav {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding: 0 30px;

  @include max-desktop {
    flex-direction: column-reverse;
  }

  &__main {
    align-items: center;
    color: $white;
    display: flex;

    @include max-tablet {
      flex-direction: column;
    }
  }

  &__image {
    background-position: center;
    background-size: cover;
    border: 2px solid $white;
    border-radius: 50%;
    height: 150px;
    margin-right: 20px;
    width: 150px;

    @include max-tablet {
      margin: 20px 0;
    }

    @include max-desktop {
      margin: 20px 20px 0 0;
    }
  }

  &__vrm {
    background: linear-gradient(0deg, #ffc60b 0%, #ffe10b 100%);
    border-left: 20px solid $blue;
    border-radius: 1rem;
    color: $navy;
    display: inline-block;

    font-family: $font-family-vrm;
    font-size: 3.6rem;
    font-weight: bold;
    letter-spacing: 4px;
    min-width: 17.5rem;
    padding: 1rem 3rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
  }
}



