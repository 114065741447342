@import '../helpers/variables';
@import '../mixins';

.pbar {
  display: flex;
  padding-bottom: 50px;
  padding-top: 20px;

  &__segment {
    color: $white;
    flex: 1;
    list-style-type: none;
    position: relative;
    text-align: center;

    a {
      color: $white;
    }

    &::before {
      background-color: $white;
      border-radius: 50%;
      bottom: -18px;
      content: '';
      display: block;
      height: 8px;
      left: 50%;
      position: absolute;
      text-align: center;
      width: 8px;
    }

    &::after {
      background-color: $white;
      bottom: -15px;
      content: '';
      height: 2px;
      left: -50%;
      position: absolute;
      width: 100%;
      z-index: 0;
    }

    &:first-child::after {
      content: none;
    }
  }

  &__segment--active::before {
    background-color: $orange;
    border-color: $orange;
  }

  &__segment--active + &__segment::after {
    background-color: $orange;
  }
}

