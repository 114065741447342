@import '../helpers/variables';
@import '../mixins';

.timeline {
  list-style: none;
  padding: 0;
  position: relative;

  &.widder {
    width: 350px;
  }
}

.timeline::after {
  background-color: $orange;
  bottom: auto;
  content: '';
  height: 100%;
  justify-content: center;
  left: auto;
  margin: auto;
  position: absolute;
  right: 50%;
  top: 0;
  width: 2px;
  z-index: -0;
}

.timeline::before {
  background: $orange;
  border: solid 2px $orange;
  border-radius: 15px;
  bottom: auto;
  content: '';
  height: 20px;
  left: auto;
  margin-right: -10px;
  position: absolute;
  right: 50%;
  top: 0;
  width: 20px;
}

.timeline-item {

  align-items: baseline;
  display: flex;
  justify-content: space-between;

  &--title {

    display: flex;
    font-size: 20px;
    font-weight: bold;

  }

  &.autohide {
    @include max-desktop {
      display: none;
    }
  }

}

