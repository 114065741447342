@import '../mixins';
@import '../helpers/variables';

// Ghost Info
.ghost-info {
  border: 3px solid $white;
  border-radius: 5px;
  color: $white;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
}
