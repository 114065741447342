@import '../helpers/variables';
@import '../mixins';

.dark-theme {
  --mhistory--bg-color1: #07112e;
  --mhistory--bg-color2: #{$black};
  --mhistory--color: #{$white};
  --mhistory--ad-bg-color: #{$navy-dark};
  --mhistory--ad-border-color: #{$orange-dark};
}

.mhistory {

  position: relative;

  &__container {
    margin: auto;
    width: 70%;

    @include max-desktop {
      width: 100%;
    }
  }

  &__card {
    background: radial-gradient(circle, var(--mhistory--bg-color1, $white) 0%, var(--mhistory--bg-color2, $white) 35%);
    border: 0.1rem solid var(--mhistory--border-color, $grey);
    color: var(--mhistory--color, $navy-dark);
    margin: 50px auto;
    padding: 30px 50px;
    width: 100%;
    z-index: 1;

    @include max-tablet {
      margin: 50px auto;
      padding: 20px;
    }
  }

  &__result {
    font-weight: bold;
  }

  &__result--pass {
    color: $green;
  }

  &__result--fail {
    color: $red;
  }

  &__content {
    margin: auto;
    width: 100%;

    &--title {
      font-weight: bold;
    }
  }

  &__advisories {
    background-color: var(--mhistory--ad-bg-color, #fef7f2);
    border: 0.1rem solid var(--mhistory--ad-border-color, $grey);
    font-weight: bolder;
    padding: 20px;

    @include max-tablet {
      padding: 10px;
    }
  }

  &__advisory-item {
    margin-left: 20px;
    margin-top: 10px;
  }

  &__fade::after {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(241, 241, 241) 80%);
    bottom: 0;
    content: '';
    height: 25em;
    margin: auto;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;
  }
}
