@import '../mixins';
@import '../helpers/variables';

.flow-breadcrumbs {
  display: flex;
  margin: 0 0 10px;
  padding: 0;
  transform: translateX(-15px);
}

.flow-breadcrumb {
  background-color: #ededed;
  color: $navy-dark;
  display: inline-block;
  flex: 1;
  font-size: 15px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding-left: 25px;
  position: relative;
  text-align: center;

  @include min-tablet {
    height: 60px;
    line-height: 60px;
  }


  &::before,
  &::after {
    border: 20px solid #ededed;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 20px 12px;
    content: '';
    height: 0;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 0;

    @include min-tablet {
      border-radius: 25px 12px;
      border-width: 28px;
      top: 2px;
    }
  }

  &::before {
    border-right-color: #ededed;
    border-top-color: #ededed;
    left: -20px;
    z-index: 1;

    @include min-tablet {
      left: -30px;
    }
  }

  &::after {
    box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
    right: -18px;
    z-index: 2;

    @include min-tablet {
      right: -25px;
    }
  }

  &:first-child::before {
    border-right-color: $grey-pale;
    border-top-color: $grey-pale;
  }

  &:last-child::after {
    box-shadow: none;
  }

  &--active {
    background-color: #e0e0e0;

    &::after {
      border-right-color: #e0e0e0;
      border-top-color: #e0e0e0;
    }
  }

  &--disabled {
    &::after {
      border-right-color: #ededed;
      border-top-color: #ededed;
    }
  }
}
